import React, { useState } from 'react'
import Layout from '../../components/Layout'
import WorkTile from '../../components/WorkTile'
import './work.css'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const Work = () => {
  const [activeSlide, setActiveSlide] = useState(2)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 100
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0
    }
  }
  console.log(activeSlide)
  return (
    <Layout>
      <div className='root'>
        <Carousel
          centerMode={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          afterChange={(previousSlide, { currentSlide }) => {
            setActiveSlide(currentSlide)
            console.log(currentSlide)
          }}
        >
          <WorkTile
            img='/anthro.jpg'
            layer1='/earth.png'
            title='Anthro'
            active={activeSlide == 2 ? true : false}
            link='anthro'
          />
          <WorkTile
            img='/test.jpg'
            title='Teabury'
            active={activeSlide == 3 ? true : false}
          />
          <WorkTile
            img='/test.jpg'
            title='Anthro'
            active={activeSlide == 1 || activeSlide == 4 ? true : false}
          />
        </Carousel>
      </div>
    </Layout>
  )
}

export default Work

import React, { useRef, useState } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { StaticImage } from "gatsby-plugin-image"
import { useSize } from '../hooks/useSize'
import { useSpring, animated } from 'react-spring'
import lerp from 'lerp'
import './workTile.css'

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 6}px,0)`
const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

const WorkTile = ({ title, img, layer1, active, link }) => {
  const [clientX, setClientX] = useState(null)
  const [clientY, setClientY] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const ref = useRef(null)
  const { width, height } = useSize(ref, imageLoaded)

  const movementLeft = lerp(20, 30, clientX / width)
  const movementRight = lerp(10, 20, clientX / width)
  const movementTop = lerp(20, 30, clientY / height)
  const movementBottom = lerp(30, 20, clientY / height)

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }))
  console.log(active)
  return (
    <AniLink to={link} cover direction='top' duration={1} bg='#E84545'>
      <div
        ref={ref}
        className='workTile'
        onMouseMove={
          active == true
            ? ({ currentTarget, clientX: x, clientY: y }) => (
                set({ xy: calc(x, y) }),
                setClientX(x - currentTarget.getBoundingClientRect().left),
                setClientY(y - currentTarget.getBoundingClientRect().top)
              )
            : undefined
        }
        onMouseLeave={() => (setClientX(width / 2), setClientY(height / 2))}
      >
        <div className='titleHolder'>
          <h2>{title}</h2>
        </div>
        <animated.div style={{ transform: props.xy.to(trans1) }}>
          <img
            src={img}
            draggable='false'
            onLoad={() => setImageLoaded(true)}
          />
          <svg
            viewBox='0 0 42 227'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='svgLeft svgSide'
          >
            <path
              d={
                'M0.5 0.50001L0.5 226.5H25.5C25.5 226.5 ' +
                movementLeft +
                ' 175.5 ' +
                movementLeft +
                ' 114C' +
                movementLeft +
                ' 52.5 25.5 0.50001 25.5 0.50001H0.5Z'
              }
              fill='#0F0F0F'
            />
          </svg>
          <svg
            className='svgRight svgSide'
            viewBox='0 0 42 227'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d={
                'M41.5 226.5L41.5 0.500015L16.5 0.500013C16.5 0.500013 ' +
                movementRight +
                ' 51.5 ' +
                movementRight +
                ' 113C' +
                movementRight +
                ' 174.5 16.5 226.5 16.5 226.5L41.5 226.5Z'
              }
              fill='#0F0F0F'
            />
          </svg>
          <svg
            viewBox='0 0 226 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='svgTop'
          >
            <path
              d={
                'M226 9.87877e-06L0 0L-1.09279e-06 25C-1.09279e-06 25 51 ' +
                movementTop +
                ' 112.5 ' +
                movementTop +
                'C174 ' +
                movementTop +
                ' 226 25 226 25L226 9.87877e-06Z'
              }
              fill='#0F0F0F'
            />
          </svg>
          <svg
            viewBox='0 0 226 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='svgBottom'
          >
            <path
              d={
                'M226 9.87877e-06L0 0L-1.09279e-06 25C-1.09279e-06 25 51 ' +
                movementBottom +
                ' 112.5 ' +
                movementBottom +
                'C174 ' +
                movementBottom +
                ' 226 25 226 25L226 9.87877e-06Z'
              }
              fill='#0F0F0F'
            />
          </svg>
        </animated.div>
        <animated.div
          style={{ transform: props.xy.to(trans2) }}
          className='layer1'
        >
          <img src={layer1} draggable='false' />
        </animated.div>
      </div>
    </AniLink>
  )
}

export default WorkTile
